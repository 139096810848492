import mount from "../components/Base/Toast/helpers/mount";
import Toast from "../components/Base/Toast/index.vue";

/**
 * Special thanks to https://github.com/MeForma
 *
 * Repository that was used to create this functionality: https://github.com/MeForma/vue-toaster
 * Demo: https://meforma.github.io/vue-toaster/
 */
const useToast = (globalOptions = {}) => {
  return {
    show(message: string, options = {}) {
      const localOptions = { message, ...options };
      const c = mount(Toast, { props: { ...globalOptions, ...localOptions } });
      return c;
    },
    success(message: string, options: Record<string, any> = {}) {
      options.type = "success";
      return this.show(message, options);
    },
    error(message: string, options: Record<string, any> = {}) {
      options.type = "danger";
      return this.show(message, options);
    },
  };
};

export default useToast;
